<template>
	<div>
		<p
			class="text-h6"
			:style="instyle"
			style="font-weight: 900; color: #3c4043"
		>
			<slot v-if="$slots.heading" name="heading" />
		</p>
		<p style="line-height: 1.3">
			<slot v-if="$slots.content" name="content" />
		</p>
	</div>
</template>
<script>
export default {
	props: {
		instyle: {
			type: String,
			default: "color: #50a1c5",
		},
	},
};
</script>
